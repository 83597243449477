import {BaseGraph} from "./base_graph";

class DriverchecksFinance extends BaseGraph {
    constructor() {
        super('driverchecks_finance');
    }

    getChartJsOptions(options, data) {
        options.plugins = {
            tooltip: {
                callbacks: {
                    label: r => {

                        if( r.dataset.label.indexOf('Drivercheck') >= 0 || r.dataset.label.indexOf('DVLA') >= 0 ) {
                            return `${r.dataset.label} (${r.formattedValue})`;
                        }

                        // get no. of checks
                        const count = ( r.dataset.count ) ? ` (${r.dataset.count[r.dataIndex]})` : '';
                        return `${r.dataset.label + count} - ${r.label}: ${app.DASHBOARD_CORE.num(r.formattedValue, true)}`;
                    }
                }
            }
        };

        // y axis £
        options.scales = { y: {ticks: {callback: r => { return app.DASHBOARD_CORE.num(r, true); } } } };
        return options;
    }
}

$(() => {
    if(app && app.DASHBOARD_CORE) {
        new DriverchecksFinance();
    } else {
        console.warn('DashboardCore not initalized.');
    }
});